import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import isMobiles from 'ismobilejs';
import { menus, IMenus } from './config';
import imgPhone from '@/assets/phone.png';
import { contactNumber } from '@/config';
import imgLogo from '@/assets/logo.png';
import style from './index.module.scss';
import classnames from 'classnames';

const MenuItem = Menu.Item;
const SubItem = Menu.SubMenu;

export default () => {
  let [isMobile, setIsMobile] = useState(isMobiles().any);
  let [open, setOpen] = useState(false);
  let [iconMenuClass, setIconMenuClass] = useState(style.iconMenu);
  let cls = isMobile ? style.mobileMenu : style.menu;
  let [menuClass, setMenuClass] = useState(cls);

  let location = useLocation();

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile(isMobiles().any);
    });
  }, []);
  useEffect(() => {
    setIconMenuClass(
      classnames(style.iconMenu, {
        [style.open]: open,
      })
    );
    setMenuClass(
      classnames(cls, {
        [style.mobileMenuOpen]: open,
      })
    );
  }, [open, isMobile, cls]);

  function renderMenu(data: IMenus[]) {
    return data.map(({ path, label, children }) => {
      if (children) {
        return (
          <SubItem title={label} key={path}>
            {renderMenu(children)}
          </SubItem>
        );
      }
      return (
        <MenuItem key={path}>
          <Link to={path}>{label}</Link>
        </MenuItem>
      );
    });
  }
  return (
    <header className={style.container}>
      <div className={style.content}>
        <div className={style.logo}>
          <img src={imgLogo} alt="logo" />
        </div>
        <div className={iconMenuClass} onClick={() => setOpen(!open)}>
          <em></em>
          <em></em>
          <em></em>
        </div>
        <Menu
          mode={isMobile ? 'inline' : 'horizontal'}
          theme="dark"
          className={menuClass}
          selectedKeys={[location.pathname]}
          defaultSelectedKeys={['Home']}
          defaultOpenKeys={isMobile ? ['Service'] : []}
          onClick={() => setOpen(false)}
        >
          {renderMenu(menus)}
        </Menu>
        <div className={style.contact}>
          <img src={imgPhone} className={style.iconPhone} alt="icon-phone" />
          <span>{contactNumber}</span>
        </div>
      </div>
    </header>
  );
};
