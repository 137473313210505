import React from 'react';
import { contactNumber, serviceUrl } from '@/config';
import iconPhone from '../Tools/assets/phone.png';
import iconService from '../Tools/assets/service.png';
import style from './index.module.scss';

export default () => {
  return (
    <div className={style.container}>
      <a href={serviceUrl}>
        <img src={iconService} className={style.iconService} alt="icon" />
        <span>在线客服</span>
      </a>
      <a href={`tel:${contactNumber.replace(/-/g, '')}`}>
        <img src={iconPhone} className={style.iconPhone} alt="icon" /> <span>电话咨询</span>
      </a>
    </div>
  );
};
