import React from 'react';
import { Link } from 'react-router-dom';
import iconPhone from '@/assets/f-phone.png';
import iconEmail from '@/assets/email.png';
import iconPosition from '@/assets/position.png';
import imgLogo from '@/assets/logo1.png';
import { location, contactNumber, email, copyright } from '@/config';
import style from './index.module.scss';

const route = [
  { name: 'Service', path: '/service/develop', label: '服务内容' },
  { name: 'Example', path: '/example', label: '客户案例' },
  { name: 'About', path: '/about', label: '关于我们' },
];
export default () => {
  return (
    <footer className={style.container}>
      <div className={style.content}>
        <div className={style.left}>
          <div className={style.logo}>
            <img src={imgLogo} alt="logo" />
          </div>
          <div className={style.companyInfo}>
            <div className={style.item}>
              <img src={iconPhone} alt="phone" />
              <a href={`tel:${contactNumber.replace(/-/g, '')}`} className={style.phoneNumber}>
                电话：{contactNumber}
              </a>
            </div>
            <div className={style.item}>
              <img src={iconEmail} alt="email" />
              <a href={`mailto:${email}`} className={style.email}>
                邮箱：{email}
              </a>
            </div>
            <div className={style.item}>
              <img src={iconPosition} alt="position" /> 地址：{location}
            </div>
          </div>
        </div>
        <div className={style.right}>
          <div className={style.nav}>
            {route.map(({ path, name, label }) => {
              return (
                <Link to={path} key={name}>
                  {label}
                </Link>
              );
            })}
          </div>
          <div className={style.copyright}>
            © 2018-2020 南京星驰信息科技有限公司 All rights reserved.
            <br />
            <a href="https://beian.miit.gov.cn/" className={style.copyrightA}>
              {copyright}
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
