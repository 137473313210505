import React from 'react';
import iconPhone from './assets/phone.png';
import iconScan from './assets/scan.png';
import iconService from './assets/service.png';
import imgQrcode from './assets/qrcode.png';
import { contactNumber, serviceUrl } from '@/config';
import style from './index.module.scss';

export default () => {
  return (
    <div className={style.container}>
      <div className={style.item}>
        <img src={iconService} alt="service" className={style.icon} />
        <a href={serviceUrl} target="__blank">
          在线客服
        </a>
      </div>
      <div className={style.item}>
        <img src={iconPhone} alt="phone" className={style.icon} />
        <div>电话咨询</div>
        <a className={style.phone} href={`tel:${contactNumber.replace(/-/g, '')}`} target="__blank">
          {contactNumber}
        </a>
      </div>
      <div className={style.item}>
        <img src={iconScan} alt="scan" className={style.icon} />
        <div>扫码关注</div>
        <img src={imgQrcode} alt="qrcode" className={style.qrcode} />
      </div>
    </div>
  );
};
