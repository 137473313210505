
export interface IMenus {
  name: string;
  path: string;
  label: string;
  children?: IMenus[]
}
export const menus: IMenus[] = [
  {
    name: "Home",
    path: "/",
    label: "首页"
  },
  {
    name: "Service",
    path: "/service",
    label: "服务内容",
    children: [
      {
        name: "Develop",
        path: '/service/develop',
        label: "定制化产品研发"
      },
      {
        name: "Counsel",
        path: '/service/counsel',
        label: "互联网创新咨询"
      },
      {
        name: "Marketing",
        path: '/service/marketing',
        label: "数字营销服务"
      },

    ]
  },
  {
    name: "Example",
    path: "/example",
    label: "客户案例"
  },
  {
    name: "About",
    path: "/about",
    label: "关于我们"
  }
]