import { lazy } from "react";

export default {
  name: "Service",
  path: "/service",
  component: lazy(() => import('./index')),
  children: [
    {
      name: "Develop",
      path: '/service/develop',
      component: lazy(() => import('./pages/Develop'))
    },
    {
      name: "Marketing",
      path: '/service/marketing',
      component: lazy(() => import("./pages/Marketing"))
    },
    {
      name: "Counsel",
      path: '/service/counsel',
      component: lazy(() => import("./pages/Counsel"))
    }
  ]
}