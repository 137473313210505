import React, { Suspense } from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';
import LayoutHeader from '@/layout/Header';
import LayoutFooter from '@/layout/Footer';
import LayoutTools from '@/layout/Tools';
import LayoutMobileTools from '@/layout/MobileTools';
import Loading from '@/views/Loading';

interface IRouters {
  name: string;
  path: string;
  component: any;
  children?: IRouters[];
}

let routes: IRouters[] = [];
// @ts-ignore
const registerRouter = require.context('@/views', true, /router\.ts$/i);
registerRouter.keys().forEach((fileName: string) => {
  let file = registerRouter(fileName).default;
  routes.push(file);
});

export default () => {
  function handlerRoutes(router: IRouters[]): any {
    return router.map(({ path, component, children }, i) => {
      if (children) {
        return handlerRoutes(children!);
      }
      return <Route exact path={path} key={i} component={component} />;
    });
  }
  return (
    <HashRouter>
      <LayoutHeader />
      <Suspense fallback={Loading()}>
        <Switch>{handlerRoutes(routes)}</Switch>
      </Suspense>
      <LayoutFooter />
      <LayoutTools />
      <LayoutMobileTools />
    </HashRouter>
  );
};
